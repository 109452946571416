import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSHeading } from "@egds/react-core/text";
import { AnalyticsConfig } from "@egds/react-core/utils";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { ProductTypeContext, ProductTypeDescriptionContext } from "components/flexComponents/TopCities/TopCities";
import { cityCard, topLevelComponentClassName, carouselItemsVisible } from "./topCitiesDestinationUtils";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";

import {
  TopCitiesProps,
  CityCard,
  TopLevelComponentClassName,
  CarouselItemsVisible,
} from "components/flexComponents/TopCities/typings";
import { withStores } from "stores";
import { IsomorphicCarousel } from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { cropResizeSrc } from "src/components/shared/BlossomImage/BlossomImage";
import { observer } from "mobx-react";
import { TopCitiesFlexModuleDestinationResult } from "typings/microserviceModels/top-cities-flex-module";
import { TrackedVisibility } from "src/components/utility/analytics/TrackedVisibility";

const TopCitiesDestination = withStores(
  "analytics",
  "flexModuleModelStore",
  "context"
)(
  observer((props: TopCitiesProps) => {
    const { templateComponent, flexModuleModelStore, context, analytics } = props;
    const {
      metadata: { id },
      config: { view = "" },
    } = templateComponent;
    const model = flexModuleModelStore.getModel(id) as TopCitiesFlexModuleDestinationResult;
    const { formatText } = useLocalization();
    const cityCardKeyHelper = new ItemKeyHelper("topCitiesCard");

    if (!model || !model.topCities || model.empty) {
      return null;
    }

    const { title, topCities, productType, productTypeDescription, hideProductIcon } = model;

    /* istanbul ignore next */
    const carouselAnalytics: AnalyticsConfig = {
      id: `LP.TopCities.${productType}`,
      callback: (theId, description) => {
        const actionTracking = theId.includes("scroll") ? Action.SCROLL : Action.IMPRESSION;
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName: theId,
          action: actionTracking,
          linkName: description,
        };
        sendDelayedTrackEvent(flexTrackingInfo, analytics);
      },
    };

    const CityCard = cityCard[view as keyof CityCard];

    const getHeadingText = () => {
      if (title) {
        return title;
      }
      const destination = context.searchContext.location?.localizedName || context.searchContext.destination.name;

      return formatText("topCities.title", destination);
    };

    const topCitiesWithOptimizedImage = topCities.map((city) => {
      const images = city.images.map((img) => ({
        ...img,
        url: cropResizeSrc(img.url, { width: 384, height: 216 }, "medium"),
      }));

      return {
        ...city,
        images,
      };
    });

    return (
      <ProductTypeContext.Provider value={productType.toLowerCase()}>
        <ProductTypeDescriptionContext.Provider value={productTypeDescription}>
          <div id={id} className={topLevelComponentClassName[view as keyof TopLevelComponentClassName]}>
            <EGDSHeading tag="h2" size={4}>
              {getHeadingText()}
            </EGDSHeading>

            <EGDSSpacing margin={{ blockstart: "three" }}>
              <TrackedVisibility rfrrId={`LP.TopCities.${productType}`} moduleName={productType}>
                <div>
                  <IsomorphicCarousel
                    buttonText={{
                      nextButton: formatText("carousel.item.next"),
                      prevButton: formatText("carousel.item.prev"),
                    }}
                    itemsVisible={carouselItemsVisible[view as keyof CarouselItemsVisible]}
                    pageBy={1}
                    peek
                    carouselName="topCitiesCarousel"
                    analytics={carouselAnalytics}
                  >
                    {topCitiesWithOptimizedImage.map((city, cityIndex) => (
                      <CityCard
                        hideProductIcon={hideProductIcon}
                        key={cityCardKeyHelper.next()}
                        city={city}
                        index={cityIndex}
                        pageType={context.searchContext.pageType}
                      />
                    ))}
                  </IsomorphicCarousel>
                </div>
              </TrackedVisibility>
            </EGDSSpacing>
          </div>
        </ProductTypeDescriptionContext.Provider>
      </ProductTypeContext.Provider>
    );
  })
);

export default TopCitiesDestination;
