import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { ProductTypeContext, ProductTypeDescriptionContext } from "components/flexComponents/TopCities/TopCities";
import { EGDSText } from "@egds/react-core/text";

interface ProductTypeText {
  hotels: string;
  flights: string;
  cars: string;
  packages: string;
  vacation_rentals: string;
}

const ProductTypeText = () => {
  const productType = React.useContext(ProductTypeContext);
  const productTypeDescription = React.useContext(ProductTypeDescriptionContext);
  const { formatText } = useLocalization();

  const productTypeText: ProductTypeText = {
    hotels: formatText("topCities.hotels"),
    flights: formatText("topCities.flights"),
    cars: formatText("topCities.cars"),
    packages: formatText("topCities.vacations"),
    vacation_rentals: formatText("topCities.vacationRentals"),
  };

  return (
    <EGDSText size={300}>{productTypeDescription || productTypeText[productType as keyof ProductTypeText]}</EGDSText>
  );
};

export default ProductTypeText;
