import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSText } from "@egds/react-core/text";
import { ProductTypeContext } from "components/flexComponents/TopCities/TopCities";

interface PriceSupportText {
  hotels: string;
  flights: string;
  cars: string;
  packages: string;
  vacation_rentals: string;
  accommodations: string;
}

const ProductPriceSupportText = () => {
  const productType = React.useContext(ProductTypeContext);
  const { formatText } = useLocalization();

  const priceSupportText: PriceSupportText = {
    hotels: formatText("topCities.hotels.perNightRoom"),
    flights: formatText("topCities.flights.roundtrip"),
    cars: formatText("topCities.cars.perDay"),
    packages: formatText("topCities.packages.flightAndHotelPerPerson"),
    vacation_rentals: formatText("topCities.hotels.perNightRoom"),
    accommodations: formatText("topCities.hotels.perNightRoom"),
  };

  return <EGDSText size={300}>{priceSupportText[productType as keyof PriceSupportText]}</EGDSText>;
};

export default ProductPriceSupportText;
