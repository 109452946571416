import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import * as React from "react";
import LazyLoad from "react-lazyload";
import { LazyLoaderPlaceHolder } from "components/utility/LazyLoaderPlaceholderWrapper";
import { useLocalization } from "@shared-ui/localization-context";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";

export const TopCityCard = ({
  topCityName,
  imageUrl,
  shortDescription,
  link,
  callToActionLink,
  callToActionLocKey,
}: {
  topCityName: string;
  imageUrl: string;
  shortDescription: string;
  link: string;
  callToActionLink: string;
  callToActionLocKey: string;
}) => {
  const { formatText } = useLocalization();

  return (
    <EGDSLayoutGrid blockSize="full_size">
      <EGDSLayoutGridItem>
        <EGDSCard border>
          <EGDSCardLink>
            <a href={link}>{topCityName}</a>
          </EGDSCardLink>
          <EGDSFigure ratio={EGDSFigureAspectRatioType.R16_9}>
            <LazyLoad placeholder={<LazyLoaderPlaceHolder name={topCityName} />} offset={250}>
              <BlossomImage alt={topCityName} src={imageUrl} />
            </LazyLoad>
          </EGDSFigure>

          <EGDSCardContentSection>
            <EGDSHeading tag="h4" size={6}>
              {topCityName}
            </EGDSHeading>
            <EGDSText size={300} spacing="two">
              {shortDescription}
            </EGDSText>
          </EGDSCardContentSection>

          {callToActionLink && callToActionLocKey && (
            <EGDSCardContentSection padded>
              <EGDSLink>
                <a href={callToActionLink}>{formatText(callToActionLocKey)}</a>
              </EGDSLink>
            </EGDSCardContentSection>
          )}
        </EGDSCard>
      </EGDSLayoutGridItem>
    </EGDSLayoutGrid>
  );
};
