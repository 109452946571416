import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSImage, EGDSFigure } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSCard, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSSpacing } from "@egds/react-core/spacing";

import ProductDateSupportText from "./ProductDateSupportText";
import ProductPriceSupportText from "./ProductPriceSupportText";
import ProductTypeText from "./ProductTypeText";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { ImageLeftCardCityCardProps } from "../../../typings";

const ImageLeftCardCityCard = (props: ImageLeftCardCityCardProps) => {
  const { city } = props;
  const { name: cityName, link, deal } = city;
  const mainImage = city.images[0];
  const { formatText } = useLocalization();

  return (
    <EGDSCard>
      <EGDSLayoutFlex>
        <EGDSLayoutFlexItem minWidth="third_width" shrink={0}>
          <div>
            <EGDSFigure ratio={EGDSFigure.AspectRatio.R1_1} imageFit="cover">
              <EGDSImage src={mainImage.url} alt={mainImage.alt} placeholderImage lazyLoading="lazy" />
            </EGDSFigure>
          </div>
        </EGDSLayoutFlexItem>

        <EGDSLayoutFlexItem grow={1}>
          <EGDSSpacing padding={{ block: "three", inline: "three" }}>
            <EGDSLayoutFlex direction="column" justifyContent="space-between">
              <EGDSLayoutFlex direction="column">
                <EGDSHeading size={5}>{cityName}</EGDSHeading>
                <ProductTypeText />
              </EGDSLayoutFlex>
              {deal && (
                <EGDSLayoutFlex direction="column">
                  <EGDSText align="right" size={300} weight="medium" theme="emphasis">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatText("topCities.productPrice", deal.priceFormatted),
                      }}
                    />
                  </EGDSText>
                  <ProductPriceSupportText />
                  <ProductDateSupportText deal={deal} />
                </EGDSLayoutFlex>
              )}
            </EGDSLayoutFlex>
          </EGDSSpacing>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
      <EGDSCardLink>
        <TrackedLink
          moduleName="topCities"
          rfrr="hotelTravelGuideLink"
          href={link}
          data-testid={`top-cities-image-left-card-${cityName}`}
        >
          {cityName}
        </TrackedLink>
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default ImageLeftCardCityCard;
