import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSText } from "@egds/react-core/text";

import { ProductTypeContext } from "components/flexComponents/TopCities/TopCities";
import { CarOfferCreationTimeUnitType } from "components/flexComponents/TopCities/utils/topCitiesEnums";
import { Deal } from "typings/microserviceModels/top-cities-flex-module";

interface DateSupportText {
  hotels: string;
  flights: string;
  cars: string;
  packages: string;
  vacation_rentals: string;
  accommodations: string;
}

const ProductDateSupportText = (props: { deal?: Deal }) => {
  const { deal } = props;

  if (!deal) {
    return null;
  }

  const productType = React.useContext(ProductTypeContext);

  const dateSupportText: DateSupportText = {
    hotels: checkInAndCheckOutText(deal),
    flights: flightsFoundText(deal),
    cars: carsFoundText(deal),
    packages: checkInAndCheckOutText(deal),
    vacation_rentals: checkInAndCheckOutText(deal),
    accommodations: checkInAndCheckOutText(deal),
  };

  return <EGDSText size={300}>{dateSupportText[productType as keyof DateSupportText]}</EGDSText>;
};

const checkInAndCheckOutText = (deal: Deal) => {
  const { formatText, formatDateString } = useLocalization();
  const { isoCheckIn, isoCheckOut } = deal;

  const checkInDateString = formatDateString(isoCheckIn, { skeleton: "MMMd" });
  const checkOutDateString = formatDateString(isoCheckOut, { skeleton: "MMMd" });

  return formatText("topCities.hotels.checkInAndCheckOutDate", checkInDateString, checkOutDateString);
};

const flightsFoundText = (deal: Deal) => {
  const { formatText } = useLocalization();

  const { priceElapsedHours } = deal;
  const elapsedHours = parseInt(priceElapsedHours, 10);

  const hoursInOneDay = 24;

  if (elapsedHours === 0) {
    return formatText("topCities.flights.justFound");
  }
  if (elapsedHours < hoursInOneDay) {
    return formatText("topCities.flights.foundHoursAgo", elapsedHours);
  }

  const days = Math.floor(elapsedHours / hoursInOneDay);

  return formatText("topCities.flights.foundDaysAgo", days);
};

const carsFoundText = (deal: Deal) => {
  const { formatText } = useLocalization();

  const { offerCreationTime, offerCreationTimeUnit } = deal;
  const creationTime = parseInt(offerCreationTime, 10);

  if (creationTime === 0) {
    return formatText("topCities.flights.justFound");
  }
  if (offerCreationTimeUnit === CarOfferCreationTimeUnitType.HOUR) {
    return formatText("topCities.flights.foundHoursAgo", 1);
  }
  if (offerCreationTimeUnit === CarOfferCreationTimeUnitType.HOURS) {
    return formatText("topCities.flights.foundHoursAgo", creationTime);
  }
  if (offerCreationTimeUnit === CarOfferCreationTimeUnitType.DAY) {
    return formatText("topCities.flights.foundDaysAgo", 1);
  }

  return formatText("topCities.flights.foundDaysAgo", creationTime);
};

export default ProductDateSupportText;
