import FullBleedImageCityCard from "./components/FullBleedImageCityCard";
import ImageTopCardCityCard from "./components/ImageTopCardCityCard";
import ImageLeftCardCityCard from "./components/ImageLeftCardCityCard";
import { CityCard, TopLevelComponentClassName, CarouselItemsVisible } from "../../typings";

export const cityCard: CityCard = {
  "full-bleed-image": FullBleedImageCityCard,
  "image-top-card": ImageTopCardCityCard,
  "image-left-card": ImageLeftCardCityCard,
};

export const topLevelComponentClassName: TopLevelComponentClassName = {
  "full-bleed-image": "TopCitiesFullBleedImage",
  "image-top-card": "TopCitiesImageTopCard",
  "image-left-card": "TopCitiesImageLeftCard",
};

export const carouselItemsVisible: CarouselItemsVisible = {
  "full-bleed-image": { lg: 3, md: 2, sm: 1 },
  "image-top-card": { lg: 4, md: 3, sm: 1 },
  "image-left-card": { lg: 3, md: 2, sm: 1 },
};
