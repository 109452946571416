import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSImage, EGDSFigure } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";

import ProductIcon from "./ProductIcon";
import ProductDateSupportText from "./ProductDateSupportText";
import ProductPriceSupportText from "./ProductPriceSupportText";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { ImageTopCardCityCardProps } from "../../../typings";

const ImageTopCardCityCard = (props: ImageTopCardCityCardProps) => {
  const { city, index, hideProductIcon } = props;
  const { name: cityName, link, deal } = city;
  const mainImage = city.images[0];
  const { formatText } = useLocalization();

  return (
    <EGDSCard>
      <EGDSFigure ratio={EGDSFigure.AspectRatio.R16_9}>
        <EGDSImage src={mainImage.url} alt={mainImage.alt} placeholderImage lazyLoading="lazy" />
        {!hideProductIcon && <ProductIcon index={index} cardType="full-bleed-image" />}
        <ProductIcon index={index} cardType="image-top-card" />
      </EGDSFigure>
      <EGDSCardContentSection>
        <EGDSLayoutFlex direction="column">
          <EGDSLayoutFlex direction="column">
            <EGDSHeading size={5}>{cityName}</EGDSHeading>
            <ProductDateSupportText deal={deal} />
          </EGDSLayoutFlex>
          {deal && (
            <EGDSLayoutFlex direction="column">
              <EGDSText align="center" size={300} weight="medium" theme="emphasis">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatText("topCities.productPrice", deal.priceFormatted),
                  }}
                />
              </EGDSText>
              <ProductPriceSupportText />
            </EGDSLayoutFlex>
          )}
        </EGDSLayoutFlex>
      </EGDSCardContentSection>
      <EGDSCardLink>
        <TrackedLink
          moduleName="topCities"
          rfrr="hotelTravelGuideLink"
          href={link}
          data-testid={`top-cities-image-top-card-${cityName}`}
        >
          {cityName}
        </TrackedLink>
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default ImageTopCardCityCard;
