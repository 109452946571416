import * as React from "react";
import { topCitiesView } from "./views/topCitiesView";
import { TopCitiesProps, TopCitiesView } from "./typings";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { withStores } from "stores";
import { observer } from "mobx-react";

export const ProductTypeContext = React.createContext("");
export const ProductTypeDescriptionContext = React.createContext("");

const TopCities = withStores(
  "flexModuleModelStore",
  "analytics"
)(
  observer((props: TopCitiesProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;

    if (!templateComponent) {
      return null;
    }

    const {
      config: { view },
    } = templateComponent;

    flexModuleModelStore.getModel(templateComponent.metadata.id);
    const View = view ? topCitiesView[view as keyof TopCitiesView] : null;

    if (!View) {
      return null;
    }

    return (
      <LazyLoad context={context}>
        <View templateComponent={templateComponent} flexModuleModelStore={flexModuleModelStore} context={context} />
      </LazyLoad>
    );
  })
);

export default TopCities;
