import TopCitiesDefault from "./TopCitiesDefault/TopCitiesDefault";
import TopCitiesFilterCarousel from "./TopCitiesFilterCarousel/TopCitiesFilterCarousel";
import TopCitiesDestinationDiscovery from "./TopCitiesDestinationDiscovery/TopCitiesDestinationDiscovery";
import TopCitiesDestination from "./TopCitiesDestination/TopCitiesDestination";
import TopCitiesCarousel from "./TopCitiesCarousel/TopCitiesCarousel";
import { TopCitiesView } from "../typings";

export const topCitiesView: TopCitiesView = {
  default: TopCitiesDefault,
  "affinity-carousel": TopCitiesFilterCarousel,
  "destination-discovery": TopCitiesDestinationDiscovery,
  "full-bleed-image": TopCitiesDestination,
  "image-top-card": TopCitiesDestination,
  "image-left-card": TopCitiesDestination,
  "image-top-card-with-description": TopCitiesCarousel,
};
