import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSImage, EGDSFigure } from "@egds/react-core/images";
import { EGDSScrim } from "@egds/react-core/scrim";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSText, EGDSSubheading } from "@egds/react-core/text";
import { EGDSCard, EGDSCardLink } from "@egds/react-core/cards";

import ProductIcon from "./ProductIcon";
import ProductDateSupportText from "./ProductDateSupportText";
import ProductPriceSupportText from "./ProductPriceSupportText";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { FullBleedImageCityCardProps } from "../../../typings";

const FullBleedImageCityCard = (props: FullBleedImageCityCardProps) => {
  const { city, index, pageType, hideProductIcon } = props;
  const { name: cityName, link, deal } = city;
  const mainImage = city.images[0];
  const { formatText } = useLocalization();

  return (
    <EGDSCard>
      <EGDSFigure ratio={EGDSFigure.AspectRatio.R16_9}>
        <EGDSImage src={mainImage.url} alt={mainImage.alt} placeholderImage lazyLoading="lazy" />
        {!hideProductIcon && <ProductIcon index={index} cardType="full-bleed-image" />}
        <EGDSScrim>
          <EGDSLayoutFlex justifyContent="space-between" alignItems="end">
            <EGDSLayoutFlex direction="column">
              {/* using h tag instead of subheading p for a11y screen reader capabilites of elemenst inside of the carousel*/}
              <EGDSSubheading tag="h4">{cityName}</EGDSSubheading>
              <ProductDateSupportText deal={deal} />
            </EGDSLayoutFlex>
            {deal && (
              <EGDSLayoutFlex direction="column">
                <EGDSText size={300} align="right">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatText("topCities.productPrice", deal.priceFormatted),
                    }}
                  />
                </EGDSText>
                <ProductPriceSupportText />
              </EGDSLayoutFlex>
            )}
          </EGDSLayoutFlex>
        </EGDSScrim>
      </EGDSFigure>
      <EGDSCardLink>
        <TrackedLink
          moduleName="topCities"
          rfrr={pageType}
          href={link}
          data-testid={`top-cities-full-bleed-image-${index}`}
        >
          {cityName}
        </TrackedLink>
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default FullBleedImageCityCard;
