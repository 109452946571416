import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSBadge, EGDSBadgeSize, EGDSBadgeTheme } from "@egds/react-core/badge";

import { ProductTypeContext, ProductTypeDescriptionContext } from "components/flexComponents/TopCities/TopCities";
import { ProductType, ProductIconType } from "components/flexComponents/TopCities/utils/topCitiesEnums";

interface IconName {
  hotels: ProductIconType;
  flights: ProductIconType;
  cars: ProductIconType;
  vacation_rentals: ProductIconType;
  accommodations: ProductIconType;
}

interface IconDescription {
  hotels: string;
  flights: string;
  cars: string;
  vacation_rentals: string;
  accommodations: string;
}

const ProductIcon = (props: { index: number; cardType: string }) => {
  const { index, cardType } = props;
  const productType = React.useContext(ProductTypeContext);
  const productTypeDescription = React.useContext(ProductTypeDescriptionContext);
  const { formatText } = useLocalization();

  const iconName: IconName = {
    hotels: ProductIconType.LOB_HOTELS,
    flights: ProductIconType.LOB_FLIGHTS,
    cars: ProductIconType.LOB_CARS,
    vacation_rentals: ProductIconType.LOB_VACATION_RENTALS,
    accommodations: ProductIconType.LOB_VACATION_RENTALS,
  };

  const iconDescription: IconDescription = {
    hotels: formatText("topCities.hotels"),
    flights: formatText("topCities.flights"),
    cars: formatText("topCities.cars"),
    vacation_rentals: formatText("topCities.vacationRentals"),
    accommodations: productTypeDescription,
  };

  if (productType === ProductType.PACKAGES) {
    return (
      <EGDSLayoutPosition type="absolute" position={{ top: "two", left: "two" }}>
        <EGDSLayoutFlex>
          <EGDSBadge theme={EGDSBadgeTheme.NOTIFICATION_2} size={EGDSBadgeSize.LARGE}>
            <EGDSIcon
              name={ProductIconType.LOB_FLIGHTS}
              id={`top-cities-${cardType}-${ProductType.FLIGHTS}-${index}`}
              title={iconDescription.flights}
              description={iconDescription.flights}
              size={EGDSIconSize.SMALL}
            />
          </EGDSBadge>

          <EGDSSpacing margin={{ inlinestart: "two" }}>
            <EGDSBadge theme={EGDSBadgeTheme.NOTIFICATION_2} size={EGDSBadgeSize.LARGE}>
              <EGDSIcon
                name={ProductIconType.LOB_HOTELS}
                id={`top-cities-${cardType}-${ProductType.HOTELS}-${index}`}
                title={iconDescription.hotels}
                description={iconDescription.hotels}
                size={EGDSIconSize.SMALL}
              />
            </EGDSBadge>
          </EGDSSpacing>
        </EGDSLayoutFlex>
      </EGDSLayoutPosition>
    );
  }

  return (
    <EGDSLayoutPosition type="absolute" position={{ top: "two", left: "two" }}>
      <EGDSBadge theme={EGDSBadgeTheme.NOTIFICATION_2} size={EGDSBadgeSize.LARGE}>
        <EGDSIcon
          name={iconName[productType as keyof IconName]}
          id={`top-cities-${cardType}-${productType}-${index}`}
          title={iconDescription[productType as keyof IconDescription]}
          description={iconDescription[productType as keyof IconDescription]}
          size={EGDSIconSize.SMALL}
        />
      </EGDSBadge>
    </EGDSLayoutPosition>
  );
};

export default ProductIcon;
