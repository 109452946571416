export enum ProductType {
  HOTELS = "hotels",
  FLIGHTS = "flights",
  CARS = "cars",
  PACKAGES = "packages",
}

export enum ProductIconType {
  LOB_HOTELS = "lob_hotels",
  LOB_FLIGHTS = "lob_flights",
  LOB_CARS = "lob_cars",
  LOB_VACATION_RENTALS = "lob_vacation_rentals",
}

export enum CarOfferCreationTimeUnitType {
  HOUR = "hour",
  HOURS = "hours",
  DAY = "day",
  DAYS = "days",
}
