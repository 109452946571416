import * as React from "react";
import { EGDSCard } from "@egds/react-core/cards";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { TopCitiesProps } from "../../typings";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSScrim, EGDSScrimType } from "@egds/react-core/scrim";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { useLocalization } from "@shared-ui/localization-context";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import {
  CityFilterCard,
  TopCitiesFlexModuleFilterCarouselResult,
} from "src/typings/microserviceModels/top-cities-flex-module";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { AttributionText } from "components/shared/AttributionText/AttributionText";
import { renderTopCitiesAttributionTextHTML } from "../../utils/renderTopCitiesAttributionTextHTML";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { withStores } from "src/stores";
import { observer } from "mobx-react";

export const TopCitiesFilterCarousel = withStores(
  "flexModuleModelStore",
  "analytics"
)((props: TopCitiesProps) => {
  const { flexModuleModelStore, templateComponent, context } = props;
  const {
    metadata: { id },
    type: moduleName = "",
  } = templateComponent;
  const keyHelper = new ItemKeyHelper("filter-top-cities");
  const model = flexModuleModelStore.getModel(id) as TopCitiesFlexModuleFilterCarouselResult | null;

  const { affinity } = context.searchContext;
  const filterCode = affinity?.code;
  const { formatText } = useLocalization();

  if (!model) {
    return null;
  }
  const { title, topCities } = model;

  return (
    <EGDSSpacing margin={{ block: "four" }}>
      <div className="TopCitiesFilterCarousel filterCarouselContainer" id={id}>
        <EGDSHeading tag="h2" size={4}>
          {title}
        </EGDSHeading>
        <EGDSSpacing margin={{ blockstart: "three" }}>
          <IsomorphicCarousel
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            itemsVisible={{ lg: 4, md: 3, sm: 1 }}
            pageBy={1}
            peek
            carouselName="FilterDestinations"
          >
            {topCities.map((city, cityIndex) => (
              <CityCard
                key={keyHelper.next()}
                city={city}
                index={cityIndex}
                context={context}
                moduleName={moduleName}
                filterCode={filterCode}
              />
            ))}
          </IsomorphicCarousel>
        </EGDSSpacing>
      </div>
    </EGDSSpacing>
  );
});

export const CityCard = observer(
  (props: {
    city: CityFilterCard;
    index: number;
    context: ExtendedContextStore;
    moduleName: string;
    filterCode: string;
  }) => {
    const { city, filterCode } = props;
    const { name, imageUrl, hotelCount, images } = city;
    const { formatText } = useLocalization();
    const [image] = images;
    // interface defines `imageUrl` as `string[] | string;`, this normalizes it
    const imageUrlString: string = imageUrl && Array.isArray(imageUrl) ? imageUrl[0] : imageUrl;

    return (
      <EGDSCard>
        <div>
          <EGDSFigure className="destinationImage" ratio={EGDSFigureAspectRatioType.R16_9}>
            <TrackedLink
              className="uitk-attribution-link"
              moduleName="topCities"
              href={city.link}
              rfrr="topCities.exploreCityHotels"
              data-testid={`destination-image-link-${props.index}`}
            >
              <BlossomImage alt={name} src={imageUrlString} />
              <EGDSScrim type={EGDSScrimType.BOTTOM} className="destinationImageText">
                <div>
                  <EGDSText size={500} overflow="truncate" weight="bold">
                    {name}
                  </EGDSText>
                  {Boolean(hotelCount > 1 && filterCode) && (
                    <EGDSText align="left" size={300}>
                      {formatText(`topCities.hotels.quantity.${filterCode}`, hotelCount)}
                    </EGDSText>
                  )}
                  <AttributionText
                    source={image?.source}
                    origin={image?.origin}
                    attribUrl={image?.attribution?.url}
                    authorName={image?.attribution?.authorName}
                    authorUrl={image?.attribution?.authorUrl}
                    licenseSuite={image?.attribution?.license?.suite}
                    licenseName={image?.attribution?.license?.name}
                    licenseUrl={image?.attribution?.license?.url}
                    overrideRender={renderTopCitiesAttributionTextHTML}
                  />
                </div>
              </EGDSScrim>
            </TrackedLink>
          </EGDSFigure>
        </div>
      </EGDSCard>
    );
  }
);

export default TopCitiesFilterCarousel;
