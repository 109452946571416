import * as React from "react";
import { RenderAttributionTextHTML } from "components/utility/MediaAttributionUtil";
import { TrackedLink } from "src/components/utility/analytics/TrackedLink";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSText } from "@egds/react-core/text";

export const renderTopCitiesAttributionTextHTML: RenderAttributionTextHTML = ({ mainText, altText, attribUrl }) => {
  const { formatText } = useLocalization();

  return (
    <div className="attributionText" title={altText}>
      <TrackedLink
        title={formatText("hero.attribution.openLink", altText)}
        className="uitk-attribution-link"
        moduleName="attributionsText.ownerLink"
        href={attribUrl}
        rfrr="Attributions"
      >
        <EGDSText inline="small" size={100} overflow="truncate">
          <span dangerouslySetInnerHTML={{ __html: mainText }} />
        </EGDSText>
      </TrackedLink>
    </div>
  );
};
